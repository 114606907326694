<template>
  <v-select
    v-bind:value="input.value"
    @change="
      input.value = $event;
      $emit('passChange', input);
    "
    :items="input.items"
    :label="input.name"
    required
    outlined
    dense
    color="#3d2cdd"
  >
  </v-select>
</template>
<script>
export default {
  name: "FormDropdown",
  props: ["input"],
  data() {
    return {};
  },
};
</script>